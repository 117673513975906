import Icon from '@ant-design/icons';

export function Sun() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z">
                        </path>
                    </svg>
                )
            }
        />
    );
}

export function Moon() {
    return (
        <Icon
            component={
                () => (
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24" height="14" viewBox="0 0 24 24" width="14">
                        <rect fill="none" height="14" width="14"></rect>
                        <path fill="white" d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z">
                        </path>
                    </svg>
                )
            }

        />

    );
}

export function Logo({ width = 200, height = 60 }) {
    return (
        <Icon
            component={
                () => (



                    <svg xmlns="http://www.w3.org/2000/svg" width="248.75" height="23.24" viewBox="0 0 248.75 23.24">
                        <defs>
                            <clipPath id="clip-Artboard_2">
                                <rect width="248.75" height="23.24" />
                            </clipPath>
                        </defs>
                        <g id="Artboard_2" data-name="Artboard – 2" clip-path="url(#clip-Artboard_2)">
                            <rect width="248.75" height="23.24" fill="rgba(255,255,255,0)" />
                            <text id="मंडी" transform="translate(132 19)" fill="#136eb4" font-size="17" font-family="NirmalaUI-Bold, Nirmala UI" font-weight="700"><tspan x="-15.016" y="0">मंडी</tspan></text>
                            <line id="Line_269" data-name="Line 269" x2="39" transform="translate(150.5 12.5)" fill="none" stroke="#136eb4" stroke-width="1" />
                            <line id="Line_270" data-name="Line 270" x2="39" transform="translate(74.5 12.5)" fill="none" stroke="#136eb4" stroke-width="1" />
                        </g>
                    </svg>




                )
            }
        />

    );
}

export function LogoSmall({ width = 70, height = 50 }) {
    return (
        <Icon style={{ marginTop: "5px" }}
            component={
                () => (
                    // <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 113.076 74" marginTop={10}>
                    //     <g id="Group_659" data-name="Group 659" transform="translate(-1542 -225)">
                    //         <path id="Path_2947" data-name="Path 2947" d="M112.647.751H40.139c-.7,4.236-.543,9.632.435,12.3A35.777,35.777,0,0,1,54.493,4.024,37.035,37.035,0,0,1,71.12,1.663c.094,1.521.246,2.926.254,4.338q.022,28.961.007,57.913a27.647,27.647,0,0,1-.183,4.038,4.075,4.075,0,0,1-1.549,2.866c-1.032.672-2.656.4-3.771.1a7.312,7.312,0,0,1-2.952-1.575,13.983,13.983,0,0,1-3.48-5.373C52.8,47.4,45.953,30.923,39.168,14.411,37.2,9.632,35.2,4.867,33.171,0H25.415c-.572,1.086-1.108,2.013-1.557,2.984-3.512,7.546-7.1,15.056-10.479,22.659-3.86,8.7-7.749,17.4-11.2,26.274A27.758,27.758,0,0,0,0,61.861c0,2.971.529,6.412,2.554,8.712.1.109.194.214.294.317,2.744,2.8,8.013,3.314,11.714,3.049a25.541,25.541,0,0,0,11.658-3.894c4.515-2.838,8.736-6.925,10.942-11.848C29.4,64.329,23.706,66.48,15.371,66.43c-7.93-.058-11.247-3.447-11.1-11.3.1-5.424,1.818-10.464,3.947-15.317,4.9-11.152,10.023-22.2,15.078-33.284.115-.253.485-.391.746-.594.118.2.227.4.336.607,2.256,4.222,4.03,8.746,5.9,13.143q4.435,10.4,8.664,20.9C41.587,47.1,44.2,53.636,46.7,60.211c.823,2.163,1.644,4.33,2.4,6.519.668,1.947,1.325,3.666-1.1,4.406l-3.563,1.082.012.854q5.308,0,10.616,0l10.726-.007,10.726-.007q5.089,0,10.177.012c.6,0,1.191.006,1.786.006.022-.261-.037-.569-.023-.837-6.561-.977-7.046-1.535-7.046-8.321Q81.395,34.954,81.413,6c.007-1.412.159-2.817.254-4.338A37.036,37.036,0,0,1,98.294,4.024a35.777,35.777,0,0,1,13.919,9.023c.978-2.665,1.13-8.06.435-12.3" transform="translate(1542 225)" fill="#3A86C1" />
                    //     </g>
                    // </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="50" viewBox="0 0 70 50">
                        <defs>
                            <clipPath id="clip-Artboard_3">
                                <rect width="70" height="50" />
                            </clipPath>
                        </defs>
                        <g id="Artboard_3" data-name="Artboard – 3" clip-path="url(#clip-Artboard_3)">
                            <rect width="70" height="50" fill="#136eb4" />
                            <text id="m" transform="translate(35 36)" fill="#fff" font-size="35" font-family="Geometric415BT-BlackA, Geometr415 Blk BT" font-weight="800"><tspan x="-15.466" y="0">M</tspan></text>
                        </g>
                    </svg>
                )
            }
        />
    );
}

export function MenuFold() {
    return (
        <Icon
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.467" height="25.032" viewBox="0 0 28.467 25.032">
                        <g id="Group_661" data-name="Group 661" transform="translate(-2231.31 -198.472)">
                            <g id="Group_660" data-name="Group 660" transform="translate(-194.102 -183.834)">
                                <rect id="Rectangle_75" data-name="Rectangle 75" width="5.607" height="28.037" rx="2.804" transform="translate(2453.449 391.997) rotate(90)" fill="#3A86C1" />
                                <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(2430.846 391.306)" fill="#fff" stroke="#3A86C1" strokeWidth="1">
                                    <circle cx="3.516" cy="3.516" r="3.516" stroke="none" />
                                    <circle cx="3.516" cy="3.516" r="3.016" fill="none" />
                                </g>
                                <rect id="Rectangle_19176" data-name="Rectangle 19176" width="5.607" height="21.866" rx="2.804" transform="translate(2447.277 382.997) rotate(90)" fill="#3A86C1" />
                                <g id="Ellipse_99" data-name="Ellipse 99" transform="translate(2441.846 382.306)" fill="#fff" stroke="#3A86C1" strokeWidth="1">
                                    <circle cx="3.516" cy="3.516" r="3.516" stroke="none" />
                                    <circle cx="3.516" cy="3.516" r="3.016" fill="none" />
                                </g>
                                <rect id="Rectangle_19177" data-name="Rectangle 19177" width="5.607" height="28.037" rx="2.804" transform="translate(2453.449 400.997) rotate(90)" fill="#3A86C1" />
                                <g id="Ellipse_100" data-name="Ellipse 100" transform="translate(2446.846 400.306)" fill="#fff" stroke="#3A86C1" strokeWidth="1">
                                    <circle cx="3.516" cy="3.516" r="3.516" stroke="none" />
                                    <circle cx="3.516" cy="3.516" r="3.016" fill="none" />
                                </g>
                            </g>
                        </g>
                    </svg>

                )
            }
        />
    );
}

export function TireIcon({ height = 45, width = 45 }) {
    return (
        <Icon
            spin={true}
            component={
                () => (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44.634 44.744">
                        <g id="tire" transform="translate(0 -0.002)">
                            <path id="Path_141" data-name="Path 141" d="M42.493,12.679a.868.868,0,0,0,.177,1.13,3.981,3.981,0,0,1,1.044,3.273,1.066,1.066,0,0,0,.156.754,3.741,3.741,0,0,1,.408,3.609c-.2.466.326.685.318,1.026-.026,1.018.245,2.068-.368,3.036-.462.73.1,1.551-.2,2.4a18.659,18.659,0,0,1-2.017,4.875A34.523,34.523,0,0,1,39.93,36.1a23.355,23.355,0,0,1-3.23,3.3c-1.286.93-2.409,2.2-3.941,2.692-1.443.467-2.682,1.576-4.2,1.608-1.408.029-2.572.937-3.962.829-1.575-.122-3.21.518-4.69.032-1.429-.469-3.024-.139-4.294-.927-1.211-.751-2.763-.691-3.808-1.626-.975-.874-2.257-1.2-3.2-2.063A23.09,23.09,0,0,1,5.3,36.72c-.951-1.318-2.243-2.47-2.756-4.034-.461-1.4-1.54-2.62-1.555-4.1-.013-1.3-.833-2.35-.792-3.62.019-.581-.368-1.184.194-1.728.135-.13.042-.42-.043-.653a3.813,3.813,0,0,1,.17-3.517c.288-.456-.2-.847-.089-1.153.376-1.046.35-2.181,1.115-3.177.5-.645.365-1.793.9-2.581.292-.429.273-1.151,1.066-1.167.111,0,.258-.359.314-.571a4.26,4.26,0,0,1,2.244-2.9,1.092,1.092,0,0,0,.574-.536A3.848,3.848,0,0,1,9.253,4.622a1.228,1.228,0,0,0,.573-.507,3.934,3.934,0,0,1,3.129-1.766c.216,0,.535-.062.627-.208A2.949,2.949,0,0,1,16.165.968C17.5.934,18.585.071,19.9.153c.5.032,1.034-.315,1.5.217.135.153.474,0,.743-.075a4.017,4.017,0,0,1,3.434.181c.159.1.419.229.538.166a2.974,2.974,0,0,1,2.922.425c1.077.607,2.328.621,3.336,1.3.492.33,1.215.365,1.343,1.165.027.168.4.358.635.4,1.306.249,1.98,1.316,2.928,2.059A20.76,20.76,0,0,1,40.772,9.86a19.857,19.857,0,0,1,1.721,2.819" transform="translate(0 0.001)" fill="#003d78" />
                            <path id="Path_142" data-name="Path 142" d="M44.514,28.933A15.576,15.576,0,1,1,28.967,44.564,15.5,15.5,0,0,1,44.514,28.933" transform="translate(-22.203 -22.176)" fill="#fdfdfd" />
                            <path id="Path_143" data-name="Path 143" d="M33.188,47.75A14.59,14.59,0,1,1,47.814,62.333,14.625,14.625,0,0,1,33.188,47.75" transform="translate(-25.438 -25.41)" fill="#f9423a" />
                            <path id="Path_144" data-name="Path 144" d="M122.768,85.738A14.076,14.076,0,0,1,118.6,95.779c-.481.489-.731.593-1.186-.067-1.2-1.747-2.515-3.419-3.75-5.145a3.556,3.556,0,0,1,1.624-5.464c2.132-.777,4.324-1.389,6.477-2.111.468-.157.6-.023.715.4a11.372,11.372,0,0,1,.292,2.349" transform="translate(-86.584 -63.562)" fill="#fdfdfd" />
                            <path id="Path_145" data-name="Path 145" d="M104.317,47.636a3.447,3.447,0,0,1-3.58-3.369c-.09-2.24-.007-4.485-.04-6.728-.009-.605.3-.613.719-.542a14.02,14.02,0,0,1,10.659,7.851c.121.237.321.526-.1.638-2.564.687-5,1.882-7.659,2.149" transform="translate(-77.18 -28.333)" fill="#fdfdfd" />
                            <path id="Path_146" data-name="Path 146" d="M52.876,40.748c0,1,.024,2.01-.005,3.014a3.621,3.621,0,0,1-4.629,3.483c-2.144-.614-4.24-1.4-6.374-2.051-.54-.166-.575-.348-.338-.79a14.079,14.079,0,0,1,10.531-7.668c.7-.118.856.118.828.767-.046,1.08-.012,2.163-.013,3.245" transform="translate(-31.725 -28.134)" fill="#fdfdfd" />
                            <path id="Path_147" data-name="Path 147" d="M36.37,86.119c-.006-3.195-.005-3.2,2.965-2.25,1.431.456,2.87.886,4.285,1.389A3.561,3.561,0,0,1,45.4,90.733c-1.238,1.771-2.569,3.476-3.806,5.247-.431.618-.689.468-1.118.031a13.906,13.906,0,0,1-4.1-9.891" transform="translate(-27.876 -63.862)" fill="#fdfdfd" />
                            <path id="Path_148" data-name="Path 148" d="M72.9,124.3a15.108,15.108,0,0,1-6.353-1.509c-.652-.315-.771-.555-.312-1.166,1.275-1.693,2.468-3.447,3.733-5.147a3.562,3.562,0,0,1,5.7-.159c1.418,1.775,2.666,3.683,4.025,5.506.421.565.054.714-.337.924A13.941,13.941,0,0,1,72.9,124.3" transform="translate(-50.557 -88.123)" fill="#fdfdfd" />
                            <path id="Path_149" data-name="Path 149" d="M87.824,90.179a2.388,2.388,0,1,1,.05-4.775,2.376,2.376,0,0,1,2.337,2.365,2.324,2.324,0,0,1-2.388,2.41" transform="translate(-65.486 -65.459)" fill="#fefefe" />
                            <path id="Path_150" data-name="Path 150" d="M93.642,40.867c0-1.276-.009-2.552.007-3.828,0-.3-.168-.725.421-.756.568-.03.5.34.5.683q0,3.886,0,7.772c0,.338.075.773-.428.794-.6.025-.494-.469-.5-.837-.008-1.276,0-2.552,0-3.828" transform="translate(-71.765 -27.808)" fill="#fdfdfd" />
                            <path id="Path_151" data-name="Path 151" d="M65.546,111.887c-.069.112-.176.313-.308.5q-2.248,3.092-4.507,6.176c-.178.242-.273.7-.776.347-.468-.324-.2-.569.005-.85q2.285-3.136,4.569-6.274c.185-.254.359-.529.733-.44.209.05.3.219.285.545" transform="translate(-45.755 -85.327)" fill="#fafafa" />
                            <path id="Path_152" data-name="Path 152" d="M112.4,118.575a3.371,3.371,0,0,1-.394.323c-.369.227-.471-.135-.611-.325-1.009-1.367-2-2.743-3-4.118-.567-.781-1.124-1.569-1.7-2.346-.177-.239-.288-.481-.065-.723a.428.428,0,0,1,.7.062q2.508,3.4,4.984,6.828a1.158,1.158,0,0,1,.083.3" transform="translate(-81.635 -85.254)" fill="#fafafa" />
                            <path id="Path_153" data-name="Path 153" d="M47.856,78.737c-.108.436-.356.485-.711.371q-3.89-1.259-7.785-2.506c-.3-.1-.45-.282-.332-.609s.379-.333.659-.242c2.557.829,5.111,1.664,7.67,2.486.287.092.482.216.5.5" transform="translate(-29.884 -58.019)" fill="#f7f7f7" />
                            <path id="Path_154" data-name="Path 154" d="M123.566,76.426c.092.548-.291.5-.508.57q-3.675,1.214-7.361,2.395c-.324.1-.751.254-.9-.2s.3-.553.619-.656c2.456-.793,4.916-1.573,7.371-2.367.4-.13.786-.308.775.255" transform="translate(-87.971 -58.281)" fill="#f8f8f8" />
                            <path id="Path_155" data-name="Path 155" d="M134.639,88.624a13.112,13.112,0,0,1-3.419,8.852c-.48.551-.8.626-1.159-.061a2.146,2.146,0,0,0-.41-.558c-.624-.592-.5-1.055.035-1.7a9.816,9.816,0,0,0,2.269-6.984c-.042-.814.134-1.141.915-1.364,1.726-.492,1.714-.536,1.768,1.238.006.193,0,.386,0,.579" transform="translate(-99.053 -66.31)" fill="#003d78" />
                            <path id="Path_156" data-name="Path 156" d="M103.409,41.38c0-1.548,0-1.55,1.376-1.193a13.28,13.28,0,0,1,8.271,6.224c.361.594.267.859-.391.989a2.6,2.6,0,0,0-.657.214c-.84.423-1.306.176-1.822-.64a9.21,9.21,0,0,0-5.561-4.07c-1.034-.271-1.459-.671-1.216-1.525" transform="translate(-79.21 -30.653)" fill="#003d78" />
                            <path id="Path_157" data-name="Path 157" d="M55.019,41.246c.315,1.092-.083,1.475-1.007,1.709a9.94,9.94,0,0,0-5.919,4.368c-.168.252-.2.76-.692.616a15.817,15.817,0,0,1-2.16-.771c-.211-.1.072-.416.174-.595a13,13,0,0,1,8.965-6.556c1.426-.3.324,1,.639,1.229" transform="translate(-34.618 -30.637)" fill="#003d78" />
                            <path id="Path_158" data-name="Path 158" d="M39.411,86.015a.789.789,0,0,1,.185-.021c2.26.636,2.243.637,2.371,2.951a10.141,10.141,0,0,0,2.347,5.922c.2.243.671.436.4.847a14.467,14.467,0,0,1-1.4,1.824c-.149.163-.372-.185-.514-.339a12.912,12.912,0,0,1-3.391-11.184" transform="translate(-30.073 -65.909)" fill="#003d78" />
                            <path id="Path_159" data-name="Path 159" d="M75.817,140.747a12.911,12.911,0,0,1-5.6-1.213c-.493-.214-.691-.439-.268-.9a2.312,2.312,0,0,0,.4-.564c.434-.934.978-1.041,1.985-.666a9.531,9.531,0,0,0,6.805.018c1.107-.419,1.566-.134,2.187.741s.556,1.22-.436,1.581a13.6,13.6,0,0,1-5.071,1" transform="translate(-53.435 -105.16)" fill="#003d78" />
                            <rect id="Rectangle_24" data-name="Rectangle 24" width="44.633" height="44.745" transform="translate(0 0.001)" fill="none" />
                        </g>
                    </svg>
                )
            }
        />
    );
}